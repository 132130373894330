<template>
  <v-card outlined :loading="loadings.mainCard">
    <v-card-title>
      {{ currentRouteName }}

      <v-spacer></v-spacer>
      <v-btn depressed color="info" to="/company/create"> add company </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table :headers="headers" :items="companies">
        <template v-slot:item.actions="{ item }">
          <v-btn color="teal lighten-2 white--text" :key="item._id">
            Add Company
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loadings: {
        mainCard: false,
      },
      footer: {},
      headers: [
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Name Created",
          value: "businessName",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Email",
          value: "businessEmail",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Fax",
          value: "businessFax",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Phone Number",
          value: "businessPhoneNumber",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Corporation Name",
          value: "corporationName",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Delivery City",
          value: "deliveryCity",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["companies", "currentUserType"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(["getAllCompanies", "getCompaniesByTenantId"]),
    getColor(city) {
      if (city > 400) return "red";
      else if (city > 200) return "orange";
      else return "green";
    },
  },
  created() {
    const self = this;
    self.loadings.mainCard = true;
    self.getAllCompanies().finally(() => {
      self.loadings.mainCard = false;
    });
  },
};
</script>

<style>
</style>
